window.logoSliderFeatureHandler = () => {
  document.addEventListener('DOMContentLoaded', () => {
    observeSliderModules();
  });

  const observeSliderModules = () => {
    const modules = document.querySelectorAll('.jsLogoSliderFeature');

    modules?.forEach(module => {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            initializeSlider(entry.target);
            observer.unobserve(entry.target);
          }
        });
      });

      observer.observe(module);
    });
  };

  const initializeSlider = (module) => {
    const slider = module.querySelector('.jsSlickLogoSliderFeature');
    if (!slider) return;

    $(slider).slick({
      centerMode: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 2000,
      variableWidth: true,
    });

    module.classList.add('visible');
  };
};
